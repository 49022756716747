import axios from 'axios';
import { useState } from 'react';

function ListForm ({ listing_type }) {
    const [formData, setFormData] = useState({
        request: listing_type,
        type: null,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        bedroom_amt: '',
        neighborhood: '',
        timeline: '',
        status: false,
        additional_info: '',
    });
    const [formSuccess, setFormSuccess] = useState(false)
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === 'phone') {
            let cleanedValue = value.replace(/\D/g, '');
    
            if (cleanedValue.length > 3 && cleanedValue.length <= 6) {
                cleanedValue = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3)}`;
            } else if (cleanedValue.length > 6) {
                cleanedValue = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
            }
    
            setFormData((prevData) => ({
                ...prevData,
                [name]: cleanedValue,
            }));
        } else if (name === 'first_name' || name === 'last_name') {
            const cleanedValue = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

            setFormData((prevData) => ({
                ...prevData,
                [name]: cleanedValue,
            }));        
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }
    };


    async function handleSubmit() {
        setLoading(true)
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const body = JSON.stringify(formData);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/new-listing/`, body, config);
            if (res.status === 200 || res.status === 201) {
                setFormSuccess(true)
            }          
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false)
        }
    }

    
    return(
        <div>
            {!formSuccess ? (
            <>
            <div className='flex flex-col text-center text-pretty text-white max-w-xl'>
                <p className='logo text-[5rem]'>New Client Form</p>
                <p className="font-bold text-white -mt-4 pb-4">
                    {listing_type === "landlord" ? "LEASE LISTING" : "HOME LISTING"}
                </p>
                <hr/>
                <p className="text-[1.3rem] pt-5 text-white pb-5">
                    {listing_type === "landlord" ? 
                        "I can help landlords list their home to find a tenant. I'm also able to help bring tenants to a property which needs" :
                        `I'm here to share my extensive market knowledge and guide you through the home selling process step by step!`}
                </p>    
                <hr/>        
            </div>
            <form onSubmit={(e) => {e.preventDefault(); handleSubmit()}}>
                <div class="border-b border-white pb-12 max-w-xl">
                    <div class="mt-10 flex flex-col gap-x-6 gap-y-8">
                        <div>
                            <label for="type" class="text-[1.2rem] font-bold text-white">PROPERTY TYPE:</label>
                            <div class="mt-2">
                                <select 
                                    required
                                    id="type" 
                                    name="type" 
                                    value={formData.type}
                                    onChange={handleChange}
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] indent-3 text-md">
                                        <option value="null"> </option>
                                        <option value="home">Home</option>
                                        <option value="condo">Condo / Apartment</option>
                                        <option value="lot">Vacant Lot</option>
                                        <option value="land">Land</option>
                                        <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <label for="first_name" class="text-base font-bold text-white">FIRST NAME</label>
                            <div className="mt-2">
                                <input
                                    required
                                    type="text"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    id="first_name"
                                    autoComplete="given-name"
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] text-md" 
                                />
                            </div>
                        </div>
                        <div>
                            <label for="last_name" class="text-[1.2rem] font-bold text-white">LAST NAME</label>
                            <div className="mt-2">
                                <input
                                    required
                                    type="text"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    id="last_name"
                                    autoComplete="given-name"
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] text-md" 
                                />
                            </div>
                        </div>
                        <div>
                            <label for="phone" class="text-[1.2rem] font-bold text-white">PHONE</label>
                            <div className="mt-2">
                                <input
                                    required
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    id="phone"
                                    autoComplete="phone"
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] text-md" 
                                />
                            </div>
                        </div>
                        <div>
                            <label for="email" class="text-[1.2rem] font-bold text-white">EMAIL</label>
                            <div className="mt-2">
                                <input
                                    required
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    id="email"
                                    autoComplete="email"
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] text-md" 
                                />
                            </div>
                        </div>
                        <div>
                            <label for="bedroom_amt" class="text-[1.2rem] font-bold text-white">BEDROOM AMOUNT</label>
                            <div class="mt-2">
                                <select 
                                    id="bedroom_amt" 
                                    name="bedroom_amt" 
                                    value={formData.bedroom_amt}
                                    onChange={handleChange}
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] indent-3 text-md">
                                        <option value="null"> </option>
                                        <option>Studio</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <label for="neighborhood" class="text-[1.2rem] font-bold text-white">NEIGHBORHOOD(S)</label>
                            <div className="mt-2">
                                <input
                                    required
                                    type="text"
                                    name="neighborhood"
                                    value={formData.neighborhood}
                                    onChange={handleChange}
                                    id="neighborhood"
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] text-md" 
                                />
                            </div>
                        </div>
                        <div>
                            <label for="timeline" class="text-[1.2rem] font-bold text-white">TIMELINE</label>
                            <div className="mt-2">
                                <input
                                    required
                                    type="text"
                                    name="timeline"
                                    value={formData.timeline}
                                    onChange={handleChange}
                                    id="timeline"
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] text-md" 
                                />
                            </div>
                        </div>
                        <div>
                            <fieldset>
                            <label for="status" class="font-bold text-[1.2rem] text-white">CURRENTLY LISTED?</label>
                            <div className="mt-6 space-y-6 flex flex-row items-center justify-start">
                                    <div className="flex gap-x-4">
                                        <input
                                            id="status-yes"
                                            name="status"
                                            type="radio"
                                            value="true"
                                            checked={formData.status === true}
                                            onChange={() => handleChange({ target: { name: 'status', value: true } })}
                                            className="hidden"
                                        />
                                        <label htmlFor="status-yes"
                                            className={`cursor-pointer px-6 py-3 rounded-lg text-white font-semibold text-[1.2rem]
                                            ${formData.status === true ? 'bg-[#72BF78]' : 'bg-gray-700'}`}
                                        >
                                            YES
                                        </label>

                                        <input
                                            id="status-no"
                                            name="status"
                                            type="radio"
                                            value="false"
                                            checked={formData.status === false}
                                            onChange={() => handleChange({ target: { name: 'status', value: false } })}
                                            className="hidden"
                                        />
                                        <label htmlFor="status-no"
                                            className={`cursor-pointer px-6 py-3 rounded-lg text-white font-semibold text-[1.2rem]
                                            ${formData.status === false ? 'bg-[#607274]' : 'bg-gray-700'}`}
                                        >
                                            NO
                                        </label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div>
                            <label for="additional_info" class="text-[1.2rem] font-bold text-white">ADDITIONAL INFO?</label>
                            <div className="mt-2">
                                <textarea
                                    type="textarea"
                                    name="additional_info"
                                    value={formData.additional_info}
                                    onChange={handleChange}
                                    id="additional_info"
                                    className="w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-[#DED0B6] indent-4 text-md" 
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-6 mt-6 text-center">
                            <button 
                                type="submit" 
                                className={`text-2xl rounded-md p-4 font-semibold w-full text-white drop-shadow border-[#607274] ${
                                    loading ? 'bg-[#7f9699] cursor-not-allowed' : 'bg-[#7f9699] hover:bg-[#72BF78] hover:translate-y-0.5'
                                }`}
                                disabled={loading}
                            >
                                {loading ? (
                                    <div role="status" className='flex items-center justify-center'>
                                        <svg aria-hidden="true" className="w-8 h-8 text-white animate-spin dark:text-gray-600 fill-white dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    ) : (
                                    "SUBMIT"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            </>
            ) : (
                <>
                  <div className='text-center'>
                    <div className='mb-7 mt-7'>
                        <i class="bi bi-check-circle-fill text-[#A0D683] text-7xl"></i>
                    </div>
                    <p className='text-white'>Thank you for filling out my webform!</p>
                    <p className='text-white'>I will be in touch within 24 hours.</p>
                </div>
                </>
            )}
        </div>
    )
}

export default ListForm
import { useState } from "react"
import BuyForm from "../forms/BuyForm";
import LeaseForm from "../forms/LeaseForm";
import ListForm from "../forms/ListForm";

function Explore () {
    const [selectedForm, setSelectedForm] = useState(null);

    const showBuyForm = () => setSelectedForm("buy");
    const showApartmentForm = () => setSelectedForm("apartment");
    const showSellForm = () => setSelectedForm("sell");
    const handleHomeRental = () => setSelectedForm("rent");
    const showLandlordForm = () => setSelectedForm("landlord");
    const showTenantForm = () => setSelectedForm("private");

    const resetExplore = () => setSelectedForm(null);;

    return (
        <div className="flex flex-col items-center bg-[#607274] w-full p-20 bg-opacity-50">  
            {selectedForm === null && (
                <>
                    <div className="flex flex-col items-center text-center">
                        <p className="text-white mb-3 text-3xl select-none font-medium w-9/12">
                            READY TO START YOUR NEXT REAL ESTATE ADVENTURE?
                        </p>
                    </div>
                    <div className="flex flex-row justify-center p-5 m-5 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" onClick={showBuyForm}>
                        <i className="bi bi-houses mr-3"></i>
                        <p className="font-semibold">BUY A HOME</p>
                    </div>
                    <div className="flex flex-row justify-center p-5 m-5 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" onClick={showApartmentForm}>
                        <i className="bi bi-building mr-3"></i>
                        <p className="font-semibold">LEASE AN APARTMENT</p>
                    </div>
                    <div className="flex flex-row justify-center p-5 m-5 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" onClick={showSellForm}>
                        <i className="bi bi-key mr-3"></i>
                        <p className="font-semibold">SELL A PROPERTY</p>
                    </div>
                    <div className="flex flex-row justify-center p-5 m-5 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" onClick={handleHomeRental}>
                        <i className="bi bi-house-lock mr-3"></i>
                        <p className="font-semibold">RENT A HOME</p>
                    </div>
                </>
            )}
            {selectedForm && (
                <>
                    {selectedForm === "private" || selectedForm === "landlord" ? (
                        <div className="flex justify-center w-full mb-10">
                            <i className="bi bi-arrow-left-circle-fill text-2xl text-[#B2A59B] cursor-pointer text-5xl hover:text-[#5e5853] hover:translate-y-0.5 drop-shadow" onClick={handleHomeRental}></i>
                        </div>
                    ) : (
                        <div className="flex justify-center w-full mb-10">
                            <i className="bi bi-arrow-left-circle-fill text-2xl text-[#B2A59B] cursor-pointer text-5xl hover:text-[#5e5853] hover:translate-y-0.5 drop-shadow" onClick={resetExplore}></i>
                        </div>
                    )}
                    {selectedForm === "buy" && <BuyForm />}
                    {selectedForm === "apartment" && <LeaseForm lease_type="apartment" />}
                    {selectedForm === "sell" && <ListForm listing_type="seller" />}
                    {selectedForm === "rent" && (
                        <div className="flex flex-col items-center">
                            <div className="flex flex-row justify-center p-5 m-5 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" onClick={showLandlordForm}>
                                <i className="bi bi-signpost-2 mr-3"></i>
                                <p className="font-semibold">I'M A LANDLORD</p>
                            </div>
                            <div className="flex flex-row justify-center p-5 m-5 bg-[#B2A59B] border-2 border-[#aba199] drop-shadow-lg rounded-lg w-96 text-white hover:bg-[#7c736d] hover:translate-y-0.5 hover:border-[#7c736d]" onClick={showTenantForm}>
                                <i className="bi bi-search mr-3"></i>
                                <p className="font-semibold">I'M A TENANT</p>
                            </div>
                        </div>
                    )}
                    {selectedForm === "landlord" && <ListForm listing_type="landlord" />}
                    {selectedForm === "private" && <LeaseForm lease_type="private" />}
                </>
            )}
        </div>
    );
}

export default Explore
import Footer from "./components/Footer";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Main from "./components/Main";
import Background from "./components/Background";
import NotFound from "./components/NotFound";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="flex flex-col items-center justify-center">
      <Navbar />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about/" element={<About />} />
        <Route path="*" element={<NotFound /> }  />
      </Routes>
      <Footer />
      <Background />
    </div>
  );
}

export default App;

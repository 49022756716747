import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
          navigate("/");
        }, 2000);
    
        return () => clearTimeout(timer);
    }, []);

    return (
      <div className="flex flex-col items-center p-20 m-20 h-screen">
        <h1 className="text-4xl font-bold">404 - Page Not Found</h1>
        <p className="mt-4 text-lg">Sorry, the page you’re looking for doesn’t exist.</p>
      </div>
    );
}
  
export default NotFound;
import { Link } from "react-router-dom"

function Navbar () {

    return (
        <>
            <nav className="w-full">
                <div className="flex flex-col flex-wrap items-center justify-between mt-10 -mb-5">
                    <div className="flex flex-row mt-5 mb-10 font-medium space-x-8 items-center justify-center">
                        <Link title="Home" to="/" className="hover:text-[#7f9699] focus:text-[#7f9699]">
                            <i class="bi bi-house-door text-4xl"></i>
                        </Link>
                        <Link title="About Me" to="/about/" className="hover:text-[#7f9699] focus:text-[#7f9699]">
                            <i className="bi bi-person-circle text-4xl"></i>
                        </Link>
                    </div>
                    <p className="logo text-8xl md:text-9xl select-none">Valerie Southern</p>
                    <p className="tracking-wider text-2xl select-none font-medium">LICENSED REAL ESTATE AGENT</p>
                </div>
                
            </nav>
        </>
    )
}

export default Navbar
import Explore from "./Explore"
import banner from "../elements/banner.jpg"

function Main () {

    return (
        <div className="flex flex-col items-center mt-20 justify-center max-w-screen-lg animator">

            <div className="w-3/4 z-0 drop-shadow-sm opacity-90">
                <img src={banner} alt="Houston, TX" className="rounded-full drop-shadow pointer-events-none" draggable="false"/>
            </div>

            <div className="drop-shadow-sm w-3/4">
                <div className="flex flex-col items-center justify-center w-full z-10">
                    <svg className="w-full opacity-50" viewBox="0 0 1200 300" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" version="1.1">
                        <path 
                            d="M0 229L40 215.2C80 201.3 160 173.7 240 148.3C320 123 400 100 480 113.7C560 127.3 640 177.7 720 207.7C800 237.7 880 247.3 960 255.5C1040 263.7 1120 270.3 1160 273.7L1200 277L1200 301L1160 301C1120 301 1040 301 960 301C880 301 800 301 720 301C640 301 560 301 480 301C400 301 320 301 240 301C160 301 80 301 40 301L0 301Z"                        
                            fill="#FFFFFF" 
                            stroke-linecap="round" 
                            stroke-linejoin="round"
                            className="fill-[#607274]">
                        </path>
                    </svg>
                </div>

                <Explore />

                <div className="flex flex-col items-center justify-center w-full z-10">
                    <svg className="w-full opacity-50 transform rotate-180" viewBox="0 0 1200 300" width="100%" height="100%" preserveAspectRatio="xMidYMid slice" version="1.1">
                        <path 
                            d="M0 229L40 215.2C80 201.3 160 173.7 240 148.3C320 123 400 100 480 113.7C560 127.3 640 177.7 720 207.7C800 237.7 880 247.3 960 255.5C1040 263.7 1120 270.3 1160 273.7L1200 277L1200 301L1160 301C1120 301 1040 301 960 301C880 301 800 301 720 301C640 301 560 301 480 301C400 301 320 301 240 301C160 301 80 301 40 301L0 301Z"                        
                            fill="#FFFFFF" 
                            stroke-linecap="round" 
                            stroke-linejoin="round"
                            className="fill-[#607274]">
                        </path>
                    </svg>
                </div>
            </div>
           

        </div>
    )
}

export default Main